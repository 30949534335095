import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Breadcrumb from '../../common/breadcrumb';
import PtTable from '../../../features/elements/table';
import PNotify from '../../../features/elements/p-notify';
import { deleteClientSubscription, getClientSubscriptions } from '../../../api/client-subscriptions';
import ClientSubscriptionsTable from '../clients/components/client-subscriptions-table';

export default function ClientSubscriptionsList() {
    const [loading, setLoading] = useState(true);
    const [ajax, setAjax] = useState({
        data: [],
        total: 0,
        totalRecords: 0,
        totalFilteredRecords: 0,
        isFiltered: false,
    });

    // Filter Variables
    const [search, setSearch] = useState('');

    const [selectAll, setSelectAll] = useState(false);
    const [tableRef, setTableRef] = useState(null);
    const [selected, setSelected] = useState([]);
    const [bulk, setBulk] = useState('');

    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
            sortable: true,
        },
        {
            Header: 'Nume Produs',
            accessor: 'productName',
            sortable: true,
        },
        {
            Header: 'Mentiuni',
            accessor: 'mentions',
        },
        {
            Header: 'Data incepere abonament',
            accessor: 'subscriptionStartDate',
            sortable: true,
            Cell: (row) => {
                const formattedDate = row.value ? new Date(row.value).toLocaleDateString() : '-';
                return (
                    formattedDate
                )
            }
        },
        {
            Header: 'Data expirare abonament',
            accessor: 'subscriptionExpirationDate',
            sortable: true,
            Cell: (row) => {
                const formattedDate = row.value ? new Date(row.value).toLocaleDateString() : '-';
                return (
                    formattedDate
                )
            }
        },
        {
            Header: 'Client',
            accessor: 'client',
            Cell: (row) => {
                const client = row?.original?.client ?? null;
                return (
                    client && (
                        <Link
                            to={`${process.env.PUBLIC_URL}/dashboard/client/${client.id}`}
                            className='on-default edit-row mr-2'
                        >
                            {client.clientName}
                        </Link>
                    ) || ("-")
                )
            }
        },
        {
            Header: 'Acțiuni',
            accessor: 'id',
            className: 'actions',
            headerClassName: 'justify-content-center',
            width: 100,
            Cell: (row) => (
                <>
                    <Link
                        to={`${process.env.PUBLIC_URL}/dashboard/client-subscription/${row.value}`}
                        className='on-default edit-row mr-2'
                    >
                        <i className='fas fa-pencil-alt'></i>
                    </Link>
                    <a
                        href='#del'
                        className='on-default remove-row'
                        onClick={(e) => deleteRow(e, row.value)}
                    >
                        <i className='far fa-trash-alt'></i>
                    </a>
                </>
            ),
        },
    ];

    useEffect(() => {
        setSelected((selected) =>
            selected.map((item) => {
                return {
                    ...item,
                    selected: selectAll,
                };
            })
        );
        document.querySelector('body').classList.add('loaded');
    }, [selectAll]);

    function isSelected(key) {
        return selected.find((item) => item.id === key && item.selected);
    }

    function onSelectChange(e, value, row) {
        setSelected(
            selected.map((item) => {
                if (item.id === row.id) {
                    return {
                        ...item,
                        selected: !item.selected,
                    };
                }
                return item;
            })
        );
    }

    async function deleteRow(e, index) {
        e.preventDefault();
        if (window.confirm('Ești sigur că vrei să ștergi aceste date?')) {

            // TODO change method to new api method
            const deleteItem = await deleteClientSubscription(index);
            if (deleteItem.status === 200) {
                setAjax({
                    ...ajax,
                    data: ajax.data.filter((item) => item.id !== index),
                    totalRecords: ajax.totalRecords - 1,
                    totalFilteredRecords: ajax.totalFilteredRecords - 1
                });
                return toast(
                    <PNotify
                        title='Success'
                        icon='fas fa-check'
                        text={deleteItem.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-success',
                    }
                );
            } else {
                return toast(
                    <PNotify
                        title='Error'
                        icon='fas fa-check'
                        text={deleteItem.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-danger',
                    }
                );
            }
        }
    }

    async function bulkAction(e) {
        e.preventDefault();
        if (!bulk) {
            return toast(
                <PNotify
                    title='Warning'
                    icon='fas fa-exclamation'
                    text='Please choose one of actions.'
                />,
                {
                    containerId: 'default',
                    className: 'notification-warning',
                }
            );
        }
        if (bulk === 'delete') {
            if (!selected.find((item) => item.selected)) {
                return toast(
                    <PNotify
                        title='Warning'
                        icon='fas fa-exclamation'
                        text='Choose at least one item.'
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-warning',
                    }
                );
            }

            let stringId = '';
            const bulkDeleteId = selected.filter((item) => item.selected);
            var bulkLength = bulkDeleteId.length;
            bulkDeleteId.forEach((item, index) => {
                if (index + 1 === bulkLength) {
                    stringId += item.id;
                } else {
                    stringId += item.id + '&';
                }
            });

            // TODO change api to new api
            const deleteItems = await deleteClientSubscription(stringId);
            if (deleteItems.status === 200) {
                setAjax({
                    ...ajax,
                    data: ajax.data.filter((media) =>
                        selected.find((item) => item.id === media.id && !item.selected)
                    ),
                    totalRecords: ajax.totalRecords - bulkLength,
                    totalFilteredRecords: ajax.totalFilteredRecords - bulkLength
                });
                return toast(
                    <PNotify
                        title='Success'
                        icon='fas fa-check'
                        text={deleteItems.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-success',
                    }
                );
            } else {
                return toast(
                    <PNotify
                        title='Error'
                        icon='fas fa-check'
                        text={deleteItems.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-danger',
                    }
                );
            }
        }
    }

    function searchTable(e) {
        e.preventDefault();
        tableRef.current.wrappedInstance.filterColumn({ id: 'search' }, search);
    }

    function fetchData(state) {
        let filtered = [...state.filtered];
        setLoading(true);
        getClientSubscriptions(
            state.page + 1,
            state.pageSize,
            filtered,
            state.sorted
        ).then((results) => {
            if (results?.data) {
                setAjax({
                    data: results.data,
                    total:
                        parseInt(results.total / state.pageSize) +
                        !!(results.total % state.pageSize),
                    totalRecords: results.total,
                    isFiltered: filtered.length ? true : false,
                });
                setSelected(
                    results.data.map((media) => {
                        return {
                            id: media.id,
                            selected: false,
                        };
                    })
                );
                setSelectAll(false);
            }
            setLoading(false);
        });
    }

    return (
        <>
            <Breadcrumb
                current={'Abonamente'}
                paths={[
                    {
                        name: 'Dashboard',
                        url: '/dashboard',
                    },
                    {
                        name: 'Clienti si Abonamente',
                        url: '#',
                    },
                    {
                        name: 'Abonamente',
                        url: '/dashboard/clients',
                    },
                ]}
            />
            <ClientSubscriptionsTable columns={columns} />
        </>
    )

}